// sessionTimeoutMixin.js

import AuthService from "../services/AuthService";

const sessionTimeoutMixin = {
    data() {
        return {
            timeout: null,
        };
    },
    created() {
        this.setupTimeout();
    },
    beforeDestroy() {
        this.clearTimeout();
    },
    methods: {
        setupTimeout() {
            // this.resetTimeout();
            this.$router.beforeEach((to, from, next) => {
                if( AuthService.isAuthenticated() ) {
                    if (to.path !== '/dynamic-assessment' && to.name !== 'GenericAssessmentView') {
                        this.resetTimeout();
                    } else {
                        this.clearTimeout();
                    }
                }
                next();
            });
        },
        resetTimeout() {
            this.clearTimeout();
            this.timeout = setTimeout(() => {
                // Show popup
                if (confirm('Your session will expire soon due to inactivity. Click OK to stay logged in.')) {
                    // User clicked OK, reset the timer
                    this.resetTimeout();
                } else {
                    // User didn't respond or clicked Cancel, logout
                    this.logout();
                }
            }, 20 * 60 * 1000); // 20 minutes
        },
        clearTimeout() {
            clearTimeout(this.timeout);
            this.timeout = null;
        },
        logout() {
            AuthService.logout();
            this.$mixpanel.track("UserLoggedOut", {
                session_timeout: false,
                screen_name: "ThankyouScreen",
            });
            this.$mixpanel.reset();
            this.$router.push("/login");

        },
    },
};

export default sessionTimeoutMixin;
